import React,{useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData'
import Sidebar from './Sidebar';
import Select from 'react-select';

const AddEvent =()=>{
    const [selectedOption, setSelectedOptions] = useState(null);
    const handleSelectChange = (selected) => {
        setSelectedOptions(selected); // Update state with selected options
        console.log("Selected options:", selected);
      };
    const [msg, setMsg]= useState('')
    const history = useHistory()
    const [userData, setUserData] = useState()
    const [strinData, setStringData] = useState()
    const[inputData, setInputData] = useState({
      date:  '',
      event_type:'',
      added_by: localStorage && localStorage.username ? localStorage.username : '',
      approved_by: null,
      stringers:[],
      remarks: '',
      slug: ''
  })
 

  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  }

  const SaveStoryData = ()=>{
    if(!inputData.slug){
        alert('Slug can not be blank.');
        return false;
    }else if(!inputData.remarks){
        alert('Remarks can not be blank.')
        return false
    }else if(!inputData.event_type){
        alert('Event Type can not be blank.')
        return false
    }else if(!inputData.approved_by){
        alert('Approved by can not be blank.')
        return false
    }else if(!selectedOption || !selectedOption.value){
        alert('Stringers can not be blank.')
        return false                                             
    }
    setMsg('')
   
      var formData = new FormData();
      formData.append('slug', inputData.slug);
      formData.append('remarks', inputData.remarks);
      formData.append('event_type', inputData.event_type);
      formData.append('approved_by', inputData.approved_by);
      formData.append('added_by', localStorage && localStorage.userId ? localStorage.userId : null);
      formData.append('stringers', [parseInt(selectedOption.value)]);

      const getStateApi =`https://stingers.ianswire.in/api/events/add/`; 
        axios({
        method: "post",
        url: getStateApi,
        data: formData,
        headers: { 
            "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

        }}).then((res) => {
        if(res && res.data && res.data.id && res.data.id){
           
            history.push('/event-list/');
        }

  


}) .catch((error) => {
  if(error && error.response && error.response.data && error.response.data.detail){
    alert(error.response.data.detail);
  }
  console.error("Error uploading image: ", error);
})}
    const [inputSearch, setSearch] = useState()
    const inputRef = useRef(null)
    const [optionsTY, setOptionsTY] =useState()
    const getStringerData = () =>{

            let imSearchAPI = `https://stingers.ianswire.in/api/stringers/`;
            if(inputSearch){
                imSearchAPI = imSearchAPI+`?search=${inputSearch}`
            }
            axios.get(
            imSearchAPI,
            {
                headers: {
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                },
            }
            ).then((res) => {
            const allStrngData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                account_no: item.account_no,
                district : item.district,
                email : item.email,
                id : parseInt(item.id),
                ifsc_code : item.ifsc_code,
                name: item.name,
                number : item.number,
                state: item.state,
                uniqueID : item.uniqueID
                    
                };
                });
            if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
            setStringData(allStrngData);
           
            const optionsvvTY = allStrngData.map(person => ({
                value: person.id,
                label: `${person.name}-${person.district}`
              }));
              setOptionsTY(optionsvvTY);
            // console.log('vbb',imgSearchD);
            }else{
                setStringData()
            }

            //console.log('TMT-varun', res);
            });
        
    }
    const inputSData=(event)=>{
        setSearch(event.target.value);
    } 
     const onStrSubmit = (e) => {
        e.preventDefault()
        getStringerData()
    }
     const getUserData = () =>{

        const userApi = `https://stingers.ianswire.in/api/users/list/`;
        axios
        .get(
            userApi
        )
        .then((res) => {
        const tempUserData =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                groups: item.groups,
                name: item.name,
                username: item.username
            };
            });
            setUserData(tempUserData);
        }); 
      
}
      useEffect(() => {
        getUserData();
        getStringerData()
      }, [msg, inputSearch]);
       
    return(
        <>
            <Header />
            <div id="layoutSidenav">
              <Sidebar />
              <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4 px-4-event">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Add Event
                            </div>
                            <div className="card-body">
								<form>
									<div className="row mb-3">								
										<div className="col-md-8 mb-3">
											<label htmlFor="inputHeading">Slug: *</label>
											<input type="text" onChange={handleOnChange} name="slug" className="form-control" id="inputHeading" value={inputData.slug}/>
										</div>
										
										<div className="col-md-8 mb-3">
											<div className="form-group">
												<label htmlFor="editShortDesc">Remarks: *</label>
												<textarea className="form-control" placeholder="Remarks" rows="7" onChange={handleOnChange} name="remarks" value={inputData.remarks}>
												</textarea>
											</div>
										</div>
									</div>
									<div className="row mb-3 col-md-8">	
										<div className="col-md-6 mb-3">
											<div className="form-group">
												<label htmlFor="inputTLCategory">Event Type: *</label>
												<select className="form-control" name="event_type" onChange={handleOnChange} value={inputData.event_type}>
													<option value="">-- Select Event type --</option>
													<option value="single_id">Single ID</option>
													<option value="multi_id">Multi ID</option>
													<option value="on_demand">On Demand</option>
												</select>
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-group">
												<label htmlFor="inputTLCategory">Added by:</label>
												<input type="text" name="added_by" className="form-control" value={inputData.added_by} readonly />
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-group">
												<label htmlFor="inputTLCategory">Approved by: *</label>
												<select className="form-control" name="approved_by"  onChange={handleOnChange} value={inputData.approved_by}>
													<option value="">-- Select Approved by --</option>
                                                    {userData && userData.length > 0 && userData.map((user, u)=>{
                                                        return(
                                                            <>
                                                            <option value={user.id} key={u}>{user.name}</option>
                                                            </>
                                                        )
                                                    })}
													
												</select>
											</div>
										</div>
										{/* <div className="col-md-6 mb-3">
											<div className="form-group">
                                            <label htmlFor="inputTLCategory">Stringers: *</label>
                                            <input list="languages" className="form-control"  placeholder="Select Stringers"/> 
                                                <datalist id="languages" onChange={handleOnChange} value={inputData.stringers} >
                                                    <option value="">-- Select --</option>
                                                    {strinData && strinData.length > 0 && strinData.map((sData, s)=>{
                                                        return(
                                                            <>
                                                            <option value={sData.id} key={s}>{sData.name}</option>
                                                            </>
                                                        )
                                                    })}
												</datalist>
											</div>
										</div> */}
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label htmlFor="inputTLCategory">Stringers: *</label>
                                <Select
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                    options={optionsTY}
                                    placeholder="Enter Stringer Name"
                                    closeMenuOnSelect={true}  // Close the menu after selection
                                    isClearable // Add clearable functionality to remove the selected option
                                />
                                                
                                  {/* <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} aria-describedby="button-addon2" className="form-control border-0 bg-light" value={inputSearch}/> */}
                                {/* <select
                                className="form-control"
                                name="stringers"
                                onChange={handleOnChange}
                                value={inputData.stringers}
                                >
                               {!inputSearch && strinData && strinData.length > 0 && <option value="">-- Select --</option>}
                               {inputSearch && (!strinData || strinData.length == 0) && <option value="">-- No data found --</option>}

                                {strinData && strinData.length > 0 && strinData.map((sData, index) => (
                                    <option value={sData.id} key={index}>
                                    {sData.name}
                                    </option>
                                ))}
                                </select> */}
                            </div>
                            </div>
									</div>
                                    <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                        <button className="btn btn-primary" type="button" onClick={SaveStoryData}>Save</button>
                                    </div>
								</form>
							</div>
                        </div>
                    </div>
                </main>
                <Footer />
               </div>
            </div>
        </>
    )
}

export default AddEvent